<template>
  <div class="pagination d-flex justify-content-center">
    <ul class="uk-pagination mt-3" uk-margin="">
      <template v-if="pagination != null">
        <template v-for="(item, index) in pagination.links">
          <template v-if="item.active">
            <li class="uk-active uk-first-column uk-disabled"><span>{{ item.label }}</span></li>
          </template>
          <template v-else>
            <li :class="item.url == null ? 'uk-active uk-disabled' : ''"><a @click="setPage(item.label)">{{
              getLabel(item.label) }}</a></li>
          </template>
        </template>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  data() {
    return {
      previous: this.$t('pagination.previous'),
      next: this.$t('pagination.next')
    }
  },
  props: {
    pagination: {
      default: null,
      type: Object,
    },
  },
  methods: {
    getLabel(label) {
      if (label === this.$t('pagination.previous')) {
        return this.previous
      }
      if (label === this.$t('pagination.next')) {
        return this.next
      }

      this.pageControl()
      return label;
    },
    setPage(page) {
      if (page === this.$t('pagination.previous')) {
        page = parseInt(this.pagination.current_page) - 1;
      }
      if (page === this.$t('pagination.next')) {
        page = parseInt(this.pagination.current_page) + 1;
      }
      this.$emit('changePage', page)
    },
    pageControl() {
      if (this.pagination.current_page === this.pagination.last_page) {
        this.pagination.links[this.pagination.links.length - 1].active = true;
        this.pagination.links[this.pagination.links.length - 1].label = this.next;
      }
      if (this.pagination.current_page === 1) {
        this.pagination.links[0].active = true;
        this.pagination.links[0].label = this.previous;
      }
    }
  }
}
</script>
<style>
.uk-margin-small-top {
  margin-top: 0px !important;
}
/*
.pagination {
  margin-right: -28px;
  margin-left: -54px;

} */
</style>