<template>
    <div class="loading-box"
        :style="{ 'min-height': minHeight, background: 'url(\'' + mediaBase + '/' + gif + '\') no-repeat center center' }">
    </div>
</template>
    
<script>
export default {

    name: "DefaultLoading",
    props: {
        gif: {
            required: false,
            type: String,
            default: 'softinya-loading.gif'
        },
        minHeight: {
            required: false,
            type: String,
            default: '470px'
        }
    }

}
</script>
    
<style scoped>
.loading-box {
    width: 100%;
    z-index: 999999999;
}
</style>